import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'login-failed',
  template: '<div>login failed</div>'
})

export class LoginFailedComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
