import { HttpEventType } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup,UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilderService } from '../services/form-builder.service';
//import { FormBuilder, FormGroup } from '@angular/forms';
import { ThemeService } from '../services/theme.service';
import { FileClass } from '../file-class';
import { ATTACHMENT} from '../attachment';
import { Subscription } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { ImagePreviewComponent } from '../image-preview/image-preview.component';
import { QueryService } from '../services/query.service';
import { AttachmentsService } from '../services/attachments.service';
import { CommandService } from '../services/command.service';
import { EventService } from '../services/event.service';
import { MatSnackBar, MatSnackBarConfig  } from '@angular/material/snack-bar';
import { ConfigService } from '../services/config.service';
//import { FileSaverService } from 'ngx-filesaver';


@Component({
  selector: 'file-upload',
  templateUrl: 'add-file.component.html',
  styleUrls: ['add-file.component.scss'],
})
export class AddFileComponent implements OnInit {
  loading = false;
  files: any[] = [];
  images: any[] = [];
  form: UntypedFormGroup;
  theme: string;
  others: string[] = [];
  uploadProgress: number[] = [];
  uploadSub: Subscription;
  imageTypes = ['image/png', 'image/jpg', 'image/jpeg'];
  expanded = true;
  gettingfiles = false;
  @Input() objectId: string;
  @Input() pathroot: any;
  //@Input() attachment_container: any;
  attachment_container: string = `${ConfigService.settings.eventType}-attachments`;
  @Input() label: string;
  @Input() attachmentpath: string;

  @Input() accordion: boolean = true;
  @Input() ticketId: string;
  @Input() ATTACHMENTS: Array<ATTACHMENT>;
  @Input() modelpath: string;
  @Input() modelid: string;
  ATTACHMENT: ATTACHMENT;

  lastView: any;
  obj: any;
  pathObj: any;
  formCurrent: UntypedFormGroup;
  errormessage: string;
  loadfile: boolean = false;
  constructor(
    private formBuilderService: FormBuilderService,
    private formBuilder: UntypedFormBuilder,
    private themeService: ThemeService,
    private queryStorage: QueryService,
    private commandService: CommandService,
    private eventService: EventService,
    public dialog: MatDialog,
    //  private _FileSaverService: FileSaverService,
    private attachment: AttachmentsService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    console.log('label:', this.label);
    console.log('objectId:', this.objectId);
    console.log('path root', this.pathroot);
    console.log('attachmentpath  set:', this.attachmentpath);
    console.log('attachment container', this.attachment_container);
    console.log('attachemnts', this.ATTACHMENTS);
    console.log('modelpath', this.modelpath);
    console.log('modelid', this.modelid);
    
    this.refresh();
    
    this.form = this.attachment.populateForm(new FileClass(), new FileClass());
    console.log('form', this.form);
    this.form = this.formBuilderService.populateForm(new FileClass(), new FileClass());
    this.theme = this.themeService.currentTheme();
  }

  refresh = () => {
    this.errormessage = '';
    console.log('refresh');
    if (this.objectId) {
      console.log('live object id' + this.objectId);
      console.log('model id:' + this.modelid);
      this.attachment
        .getAttachmentObject(this.objectId, `${ConfigService.settings.eventType}`, this.modelpath, this.modelid)
        .subscribe((data: any) => {
          console.log('Object returned:', data);
          this.ATTACHMENTS = data;
          let attachmentarray = data;
          // console.log("get attach data", attachmentarray)
          console.log('get attachment model id:' + this.modelid);
          this.attachment
            .getAttachments(
              `${ConfigService.settings.eventType}` + '-events',
              `${ConfigService.settings.eventType}` + '-attachments',
              this.objectId,
              this.attachmentpath,
              this.modelid
            )
            .subscribe((data: any) => {
              
              if (data.body && data.body.files) {
                this.files = data.body.files.filter((fil: any) => {
                  return (
                    this.ATTACHMENTS.filter((fil2) => {
                      return fil2.Id === fil.Id;
                    }).length > 0
                  );
                });
                
                this.gettingfiles = false;
              }
            });
        });
    }
  };

  onChange = (files: FileList) => {
    this.errormessage = '';

    const unSupportedFiles: any[] = [];

    console.log('files', files);
    console.log('attachmentpath to load file:', this.attachmentpath);

    Object.values(files).forEach((file: File, index: number) => {
      const reader = new FileReader();
      const fileObj: any = {};
      // if (this.imageTypes.includes(file.type)) {
      reader.readAsDataURL(file);
      reader.onload = (event: any) => {
        fileObj.file = file;
        if (file.size <= ConfigService.settings.allowedfilesize) {
          const filenameSplit = file.name.split('.');
          const fileExtension = filenameSplit[filenameSplit.length - 1];
          if (ConfigService.settings.allowedextensions.indexOf(fileExtension.toLowerCase()) !== -1) {
        fileObj.name = file.name;
        if (this.imageTypes.includes(file.type)) {
          fileObj.img = event.target.result;
          console.log("is an image")
        }
        fileObj.data = event.target.result;
        fileObj.type = file.type;
        fileObj.path = this.attachmentpath;
        fileObj.progress = 0;
        this.files.push(fileObj);
        this.fillForm();
        this.formCurrent.get('FILE_NAME').setValue(file.name);
        this.formCurrent.get('FILE_TYPE').setValue(file.type);
      //  this.formCurrent.get('FILE_PATH').setValue(fileObj.path);
        console.log('formCurrent', this.formCurrent);
        fileObj.Id = this.formCurrent.get('Id').value;
        this.raise();
        console.log('uploading file', file, fileObj);
        this.sendFile(file, fileObj);
        this.openSnackBar('File(s) uploaded successfully', 'OK');
          } else {
            unSupportedFiles.push(file.name);
            console.log(index);
          }
        } else {
          this.openSnackBar(
            'File is too large. Maximum size allowed is 50MB, please resize or select an alternative file',
            'OK'
          );
        }
        if (unSupportedFiles.length && index === files.length - 1) {
          console.log(unSupportedFiles.join(','));
          this.openSnackBar(
            `The following files(s) you are trying to upload are not supported by the system.
             ${unSupportedFiles.join(',')}
            Please contact your Business Administrator if you have any questions.`,
            'OK'
          );
        }
      };
     
    });
  };

  reset = () => {
    this.uploadProgress = null;
    this.uploadSub = null;
  };
  deleteImage = (file: any) => {
    console.log('delete image:', file);
    this.raiseDelete(file);
    console.log('files:', this.files);
    this.files = this.files.filter((fil) => {
      return fil.Id != file.Id;
    });
    this.openSnackBar('File has been deleted successfully', 'OK');
  };

  download = (file: any) => {

    let element = document.createElement('a');
    element.setAttribute('href', file.data);
    element.setAttribute('download', file.name);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);

  };

  onUpload = (files: any, index: any = null) => {
    if (Array.isArray(files)) {
      files.forEach((file: File, index: number) => {
        this.sendFile(file, index);
      });
    } else {
      this.sendFile(files, index);
    }

    this.loading = !this.loading;
  };

  sendFile = (file: File, fileObj: any) => {
    console.log('file', file);
    let fullpath = this.objectId + '/' + (fileObj.path ? fileObj.path + '/' : '') + fileObj.Id;
    
    this.loadfile = true;
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    this.uploadSub ==
      this.attachment.saveAttachments(this.attachment_container, fullpath, fileObj, formData).subscribe(
        (event) => {
         
          this.loadfile = false;
          
        },
        (err) => {
          this.errormessage = err.error;
          this.loadfile = false;
          fileObj.error = err.error;
        }
      );
  };

  openDialog = (data: any): void => {
    const dialogRef = this.dialog.open(ImagePreviewComponent, {
      // width: 'auto',
      // height: 'auto',
      data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('result', result);
    });
  };

  
  raiseDelete = (file: any) => {
    
    let mp = '';
    if (this.modelpath) {
      mp = '.' + this.modelpath + ':' + this.modelid;
      
    }

    let eventBody = { path: `data:${this.objectId}${mp}.ATTACHMENT:${file.Id}`, Id: file.Id };
    this.commandService
      .raise(
        `${ConfigService.settings.eventType}.` + this.objectId,
        `${ConfigService.settings.projectCode}.${ConfigService.settings.projectPath}.ATTACHMENT.Deleted`,
        `events`,
        eventBody,
        
      )
      .subscribe((data) => {
        console.log('Raised:', data);
        if (!this.lastView) {
          this.lastView = data[0].data;
        } else {
          this.lastView = this.eventService.reducer(this.lastView, data[0]);
        }
        
      });
  };

  raise = () => {
    console.log('pathObj', this.pathObj);
    let eventBody = this.eventService.getEventBodydyn(this.formCurrent, this.pathObj);
    console.log('eventBody', eventBody);
    console.log(`${ConfigService.settings.eventType}.` + this.objectId);
    console.log(
      `${ConfigService.settings.projectCode}.${ConfigService.settings.projectPath}.ATTACHMENT.${this.pathObj.action}`
    );

    this.commandService
      .raise(
        `${ConfigService.settings.eventType}.` + this.objectId,
        `${ConfigService.settings.projectCode}.${ConfigService.settings.projectPath}.ATTACHMENT.${this.pathObj.action}`,
        `events`,
        eventBody,
        
      )
      .subscribe((data) => {
        console.log('Raised:', data);
        if (!this.lastView) {
          this.lastView = data[0].data;
        } else {
          this.lastView = this.eventService.reducer(this.lastView, data[0]);
        }
       
      });
  };

  fillForm(): void {
    this.lastView = this.ATTACHMENT;
    console.log('attachment:', this.ATTACHMENT);
    if (this.pathroot.split('.').length === 1) {
      this.pathroot = this.pathroot + '.ATTACHMENT';
    }
    this.ATTACHMENT = new ATTACHMENT();
    this.pathObj = this.eventService.getPathObjdyn(this.ATTACHMENT, this.ATTACHMENT.Id, this.pathroot);
    console.log(' formFill this.pathObj:', this.pathObj);
    this.obj = this.eventService.populateFormdyn(new ATTACHMENT(), this.pathObj, this.ATTACHMENT.Id);
    console.log('obj here:', this.obj);
    console.log('obj.form:', this.obj.form);
    this.formCurrent = this.formBuilder.group(this.obj.form);
    console.log('formCurrent:', this.formCurrent);
    console.log('details', this.obj.detail);
    
  }

  openSnackBar(message: string, action: string) {
    const config = new MatSnackBarConfig();
    config.panelClass = ['snack-bar'];
    config.horizontalPosition = 'center';
    config.verticalPosition = 'bottom';
    this._snackBar.open(message, action, config);
  }
  

  //   dialogRef.afterClosed().subscribe((result) => {
  //     console.log('result', result);
  //   });
  // };
}
