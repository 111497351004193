import { Injectable } from '@angular/core';
import { HttpBackendClient } from './httpBackendClient';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  static settings: any;

  constructor(private http: HttpBackendClient) {}

  async getsettings() {
    await this.http
      .get('assets/config.json')
      .toPromise()
      .then((data) => {
        ConfigService.settings = data as any;
        return;
      });
  }
}
