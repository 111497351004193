import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { QueryService } from '../services/query.service';
import { ConfigService } from '../services/config.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { Sors } from '../models/interface';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  fiscalyear =
    moment().quarter() === 1
      ? moment().subtract(1, 'year').format('YYYY')
      : moment().format('YYYY');

  constructor(private queryService: QueryService) {}

  _chartsData$: Observable<Sors[]> = this.queryService
    .getAndBuild(ConfigService.settings.account, 'sorsreport-events', this.fiscalyear, true)
    .pipe(
      map((value: any) => value?.SORS),
      shareReplay(1),
      catchError(this.handleError)
    );

  _chartsPositve$: Observable<Sors[]> = this._chartsData$
    .pipe(
      map((value) => value?.filter((data: Partial<Sors>) => data.SORS_TYPE === 'Positive')),
    );


  _chartsHazard$: Observable<Sors[]> = this._chartsData$
    .pipe(
      map((value) => value?.filter((data: Partial<Sors>) => data.SORS_TYPE === 'Hazard')),
    );

    private handleError(err: any): Observable<never> {
      let errorMessage: string;
      if (err.error instanceof ErrorEvent) {
        errorMessage = `An error occurred: ${err.error.message}`;
      } else {
        errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
      }
      console.error(err);
      return throwError(errorMessage);
    }  
}
