<mat-accordion *ngIf="accordion">
  <mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header>
      <mat-panel-title><span [ngClass]="[theme, 'panel-title']">Supporting Documents</span></mat-panel-title>
      <mat-panel-description fxLayout="row" fxLayoutAlign="flex-end center">
        <mat-icon color="primary">note_add</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <form [formGroup]="form" *ngIf="form">
      <mat-form-field appearance="outline">
        <mat-label>{{ label }}</mat-label>
        <div fxLayout="row" fxLayoutAlign="flex-start center" class="images-container" fxLayoutGap="5px"></div>
        <ngx-mat-file-input (change)="onChange($event.target.files)" formControlName="SORS_FILES" multiple="true">
        </ngx-mat-file-input>
        <mat-icon matSuffix>folder</mat-icon>
        <!-- <mat-hint align="end">Formats: .png, .jpg, .jpeg .pdf .doc .xlsx</mat-hint> -->
      </mat-form-field>
    </form>
    <div>
      <mat-progress-bar *ngIf="loadfile" mode="indeterminate" color="primary"></mat-progress-bar>

      <ng-container *ngFor="let img of files; let i = index">

        <div *ngIf="img.progress !== 100" fxLayout="column" class="images-container">
          <!-- <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" style="width: 100%"> -->
          <div class="split">
            <div class="column">
              <img *ngIf="img.img" class="img" alt="uploaded images" [src]="img.img" (click)="openDialog(img)" />
              <!-- <div  *ngIf="img.name.split('.')[img.name.split('.').length-1]==='docx'"> -->
                <div  *ngIf="!img.img">
                <img src="../../../assets/document.svg" style='width: 50%;' alt="Document">
              </div>
              <mat-form-field>
                <mat-label>File Name</mat-label>
                <input matInput [(ngModel)]="img.name" />
              </mat-form-field>
            </div>
            <div class="column">
              <div class="delete">
                <button mat-icon-button *ngIf="!img.deleteclicked"color="warn" (click)="img.deleteclicked=true">
                  <mat-icon>delete_forever</mat-icon>
                </button>
                <div>
                  <button mat-button *ngIf="img.deleteclicked" color=warn (click)="deleteImage(img)">click to confirm delete</button>
                  <button mat-button *ngIf="img.deleteclicked" color=warn (click)="img.deleteclicked=false">cancel</button>
                </div>
              </div>
              <div class="delete">
                <button mat-icon-button color="primary" (click)="download(img)">
                  <mat-icon>file_download</mat-icon>
                </button>
              </div>
            </div>

            <!-- </div> -->
            <!-- <div class="progress-bar-container" fxLayout="column" fxLayoutAlign="center center">
              <mat-progress-bar
                mode="determinate"
                color="primary"
                class="progress-bar"
                [value]="img.progress"
                *ngIf="img.progress"
              ></mat-progress-bar>
              
            </div> -->
            <!-- <mat-icon (click)="onUpload(img.file, i)">file_upload</mat-icon> -->
          </div>
        </div>
      </ng-container>
      <div *ngIf="errormessage" class="layout">
        <div class="error">{{ errormessage }}</div>
        <button mat-button (click)="refresh()" color="primary">Clear Error</button>
      </div>
    </div>
    <!-- <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="flex-start center" class="complete">
      <ng-container *ngFor="let img of files; let i = index"> -->
    <!-- <div *ngIf="img.progress === 100" class="images-container-complete">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px"> -->
    <!-- <img *ngIf="img.img" class="img" alt="uploaded images" [src]="img.img" (click)="openDialog(img)" /> -->
    <!-- <div class="img" *ngIf="!img.img"></div>
            <mat-form-field appearance="outline">
              <mat-label>File Name</mat-label>
              <input matInput [(ngModel)]="img.name" readonly="true" />
            </mat-form-field> -->
    <!-- </div>
        </div> -->
    <!-- </ng-container>
    </div> -->
    <!-- <button mat-raised-button color="primary" (click)="onUpload(files)">Upload All</button> -->
    <!-- <button (click)="getImages()">Get images</button> -->
  </mat-expansion-panel>
</mat-accordion>

<ng-container *ngIf="!accordion">
  <form [formGroup]="form" *ngIf="form">
    <mat-form-field appearance="outline">
      <mat-label>Upload Relevant Documents</mat-label>
      <div fxLayout="row" fxLayoutAlign="flex-start center" class="images-container" fxLayoutGap="5px">
        <div class="img" *ngFor="let files of others; let i = index" fxLayout="row" fxLayoutAlign="center center"
          alt="uploaded image">
          <h4>{{ files }}</h4>
        </div>
      </div>
      <ngx-mat-file-input (change)="onChange($event.target.files)" formControlName="SORS_FILES" multiple="true">
      </ngx-mat-file-input>
      <mat-icon matSuffix>folder</mat-icon>
      <mat-hint align="end">Formats: .png, .jpg, .jpeg .pdf .doc .xlsx</mat-hint>
    </mat-form-field>
  </form>
  <div *ngFor="let files of images; let i = index" fxLayout="column" class="images-container">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" style="width: 100%">
      <div>
        <img class="img" alt="uploaded images" [src]="files.img" (click)="openDialog(files)" />
        <mat-form-field>
          <mat-label>File Name</mat-label>
          <input matInput [(ngModel)]="files.name" />
        </mat-form-field>
      </div>
      <div class="progress-bar-container">
        <mat-progress-bar mode="determinate" color="primary" class="progress-bar" value="60"></mat-progress-bar>
      </div>
      <mat-icon (click)="onUpload(files)">file_upload</mat-icon>
    </div>
  </div>
  <button mat-raised-button color="primary">Upload All</button>
</ng-container>