import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class QueryService {
  constructor(private httpClient: HttpClient) {}

  getList(account, container) {
    const httpCall = {
      url: 'getList',
      options: {
        params: new HttpParams()
          .set('account', ConfigService.settings.account)
          .set('container', container),
      },
    };

    return this.httpClient.get<any>(httpCall.url, httpCall.options).pipe(
      map((data) => {
        console.log('data:', data);
        return data;
      })
    );
  }

  getBlob(account, container, blob) {
    const httpCall = {
      url: `getBlob`,
      options: {
        params: new HttpParams()
          .set('account', ConfigService.settings.account)
          .set('container', container)
          .set('blob', blob),
      },
    };

    return this.httpClient.get<any>(httpCall.url, httpCall.options).pipe(
      map((data) => {
        return data;
      })
    );
  }
  getAndBuild(account, container, blob, secure) {
    const httpCall = {
      url: `getAndBuild`,
      options: {
        params: new HttpParams()
          .set('account', ConfigService.settings.account)
          .set('container', container)
          .set('blob', blob)
          .set('skipdecrypt', secure),
      },
    };

    return this.httpClient.get<any>(httpCall.url, httpCall.options).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getAndBuildBlob(account, container, blob, secure) {
    console.log('here');
    const httpCall = {
      url: `getAndBuildBlob`,
      options: {
        params: new HttpParams()
          .set('account', ConfigService.settings.account)
          .set('container', container)
          .set('blob', blob)
          .set('skipdecrypt', secure),
      },
    };

    return this.httpClient.get<any>(httpCall.url, httpCall.options).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getAlias(account, container, blob, secure) {
    console.log('here ' + secure);
    const httpCall = {
      url: `getAlias`,
      options: {
        params: new HttpParams()
          .set('account', ConfigService.settings.account)
          .set('container', container)
          .set('blob', blob)
          .set('skipdecrypt', secure ? 'true' : 'false'),
      },
    };

    return this.httpClient.get<any>(httpCall.url, httpCall.options).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
