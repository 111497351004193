import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  private readonly _currentTheme: BehaviorSubject<string> = new BehaviorSubject('Dark-Blue');
  readonly currentTheme$ = this._currentTheme.asObservable();
  // deals with the color themes
  themes: string[] = ['Dark-Blue', 'Teal', 'Grey'];

  currentTheme = () => {
    return this._currentTheme.getValue();
  };

  setChipClassName(theme: string) {
    return this._currentTheme.getValue() === theme ? `${theme} active` : theme;
  }

  setTheme(theme: string) {
    this._currentTheme.next(theme);
  }
}
