export class BusinessEvent {
  id = '';
  eventType = '';
  subject = '';
  eventTime = '';
  data: any;
  dataVersion = '1.0';
  metadataVersion = '';
  eventTopic = '';
}
