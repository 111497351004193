<div class="card">
    <mat-progress-bar
      *ngIf="working"
      mode="indeterminate"
      color="primary"
    ></mat-progress-bar>
  
    <form [formGroup]="formCurrent" *ngIf="formCurrent">
      <mat-form-field appearance="outline">
        <input
          matInput
          type="datetime-local"
          placeholder="Date"
          formControlName="SORS_DATE"
        />
      </mat-form-field>
  
      <mat-form-field appearance="outline">
        <mat-label>Observer</mat-label>
        <input matInput formControlName="SORS_OBSERVER" type="text" />
        <mat-error
          *ngIf="formCurrent.controls['SORS_OBSERVER'].hasError('required')"
        >
          Please select valid value
        </mat-error>
      </mat-form-field>
  
      <mat-form-field appearance="outline">
        <mat-label>Observer's Company</mat-label>
        <input
          matInput
          formControlName="SORS_OBSERVER_COMPANY"
          [matAutocomplete]="companyauto"
          (blur)="checkValue('SORS_OBSERVER_COMPANY')"
        />
        <mat-autocomplete
          #companyauto="matAutocomplete"
          (optionSelected)="selectedValue($event.option.value)"
        >
          <mat-option
            *ngFor="
              let company of this.company$ | async | searchFilter: companyText
            "
            [value]="company.LEVEL2_ATTRIBUTE2"
          >
            {{ company.LEVEL2_ATTRIBUTE2 }}
          </mat-option>
        </mat-autocomplete>
  
        <mat-hint align="end"> Choose One </mat-hint>
        <mat-error
          *ngIf="
            formCurrent.controls['SORS_OBSERVER_COMPANY'].hasError('required')
          "
        >
          Please select valid value
        </mat-error>
      </mat-form-field>
  
      <mat-form-field appearance="outline">
        <mat-label>Location</mat-label>
        <input
          matInput
          formControlName="SORS_ADDRESS"
          ngx-gp-autocomplete
          (onAddressChange)="handleAddressChange($event)"
        />
        <mat-hint align="end"> Start typing </mat-hint>
        <mat-error
          *ngIf="formCurrent.controls['SORS_ADDRESS'].hasError('required')"
        >
          Please type valid value
        </mat-error>
      </mat-form-field>
  
      <div class="container">
        <button
          mat-button
          *ngIf="formCurrent.get('SORS_GIS')"
          (click)="openMap()"
        >
          <mat-icon color="primary">explore</mat-icon>
          Map
        </button>
      </div>
  
      <div *ngIf="map" style="margin-bottom: 10px">
        <google-map
          height="500px"
          width="100%"
          [zoom]="zoom"
          [center]="center"
          [options]="options"
          (mapClick)="mapClick($event)"
        >
          <map-marker
            #somemarker="mapMarker"
            [position]="center"
            [options]="marker.options"
            (mapDragend)="dragEnd($event)"
          >
          </map-marker>
          <map-info-window>{{ infoContent }}</map-info-window>
        </google-map>
      </div>
  
      <mat-form-field appearance="outline">
        <mat-label>Responsible Company</mat-label>
        <input
          matInput
          formControlName="SORS_RESPONSIBLE_COMPANY"
          [matAutocomplete]="rcompanyauto"
          (blur)="checkValue('SORS_RESPONSIBLE_COMPANY')"
        />
        <mat-autocomplete
          #rcompanyauto="matAutocomplete"
          (optionSelected)="selectedValue($event.option.value)"
        >
          <mat-option
            *ngFor="
              let company of this.company$ | async | searchFilter: rcompanyText
            "
            [value]="company.LEVEL2_ATTRIBUTE2"
          >
            {{ company.LEVEL2_ATTRIBUTE2 }}
          </mat-option>
        </mat-autocomplete>
  
        <mat-hint align="end"> Choose One </mat-hint>
        <mat-error
          *ngIf="
            formCurrent.controls['SORS_RESPONSIBLE_COMPANY'].hasError('required')
          "
        >
          Please select valid value
        </mat-error>
      </mat-form-field>
  
      <mat-form-field appearance="outline">
        <mat-label>Project</mat-label>
        <input
          matInput
          formControlName="SORS_PROJECT"
          [matAutocomplete]="projectauto"
          (blur)="checkValue('SORS_PROJECT')"
        />
        <mat-autocomplete
          #projectauto="matAutocomplete"
          (optionSelected)="selectedValue($event.option.value)"
        >
          <mat-option
            *ngFor="
              let project of this.project$ | async | searchFilter: projectText
            "
            [value]="project.LEVEL2_ATTRIBUTE2"
          >
            {{ project.LEVEL2_ATTRIBUTE2 }}
          </mat-option>
        </mat-autocomplete>
  
        <mat-hint align="end"> Choose One </mat-hint>
        <mat-error
          *ngIf="formCurrent.controls['SORS_PROJECT'].hasError('required')"
        >
          Please select valid value
        </mat-error>
      </mat-form-field>
  
      <mat-form-field appearance="outline">
        <mat-label>Are you reporting a Hazard or Positive Observation?</mat-label>
        <mat-select formControlName="SORS_TYPE">
          <mat-option value="Positive">Positive Observation</mat-option>
          <mat-option value="Hazard">Hazard</mat-option>
        </mat-select>
        <mat-hint align="end"> Choose One </mat-hint>
        <mat-error *ngIf="formCurrent.controls['SORS_TYPE'].hasError('required')">
          Please select valid value
        </mat-error>
      </mat-form-field>
  
      <mat-form-field appearance="outline" *ngIf="formCurrent.value.SORS_TYPE">
        <mat-label>Observation</mat-label>
        <textarea matInput formControlName="SORS_OBSERVATION"></textarea>
      </mat-form-field>
  
      <mat-form-field
        class="tag-chip-list"
        appearance="fill"
        *ngIf="formCurrent.value.SORS_TYPE"
      >
        <mat-label>
          Please add any other tags you feel would fit this SOR
        </mat-label>
        <mat-chip-grid #chipList aria-label="Tag Selection">
          <mat-chip-row
            *ngFor="let word of tagsFound$ | async"
            [removable]="removable"
            selected="true"
            (removed)="removeTag(word)"
            color="accent"
          >
            {{ word.LEVEL2_ATTRIBUTE2 }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip-row>
  
          <input
            placeholder="Search for Tags."
            #tagInput
            formControlName="SORS_TAG_SEARCH"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event)"
          />
        </mat-chip-grid>
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="selectTag($event)"
        >
          <mat-option
            *ngFor="
              let word of formCurrent.value.SORS_TYPE === 'Hazard'
                ? hazardCats
                : (positiveCats
                  | searchFilter
                    : (observationText
                        ? observationText
                        : formCurrent.value.SORS_TAG_SEARCH));
              set
            "
            [value]="word"
          >
            {{ word.LEVEL2_ATTRIBUTE2 }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
  
      <!-- <mat-form-field>
              <mat-label>Category</mat-label>
              <input formControlName="SORS_OBS_CATEGORY" matInput/>
            </mat-form-field> -->
  
      <mat-form-field
        appearance="outline"
        *ngIf="formCurrent.value.SORS_TYPE === 'Hazard'"
      >
        <mat-label>Immediate Action Taken</mat-label>
        <textarea matInput formControlName="SORS_IMMEDIATE_ACTION"></textarea>
      </mat-form-field>
  
      <!-- <ng-container *ngIf="files"> -->
      <!-- {{SORS.ATTACHMENT.length}} -->
      <file-upload
        label="Upload SORS Documents"
        [objectId]="formCurrent.get('Id').value"
        [pathroot]="pathroot"
        [ATTACHMENTS]="SORS.ATTACHMENT"
      >
      </file-upload>
      <!-- </ng-container> -->
    </form>
  
    <div class="container">
      <button mat-raised-button color="primary" type="button" (click)="raise()"> 
        <mat-icon>
            update
        </mat-icon>
        Update 
      </button>
<!--     
      <button type="button" mat-raised-button color="primary" *ngIf="submitted" (click)="closebtn.emit()">
        Close
      </button> -->
    </div>
  </div>
  