import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { ConfigService } from '../services/config.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
 
  constructor(private userService: UserService) {}
  // observables
  user: Observable<any>;
  public b2bUser: boolean = false;

  ngOnInit(): void {

    this.user = this.userService.aadDetails$;

    this.user.subscribe((user: any) => {
      if (user.username.indexOf(ConfigService.settings.email) < 0) {
        this.b2bUser = true;
      }
    });
  }
}
