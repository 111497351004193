import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ApplicationInsightsService } from './services/applicationInsights.service';
import { ConfigService } from './services/config.service';
import { UserService } from './services/user.service';

import { AAD, User } from './models/user-classes';

import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionStatus, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private appInsights: ApplicationInsightsService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private userService: UserService,
    private msalBroadcastService: MsalBroadcastService
    ) {}

  userSub: Subscription;
  title = 'Safety Observations';
  userAccount: any
  appDetails = {
    version: '',
    release: '',
  };

  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  ngOnInit() {

    this.appDetails.version = ConfigService.settings.version;
    this.appDetails.release = ConfigService.settings.release;

    this.loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${ConfigService.settings.googleMapsConfig.key}&libraries=${ConfigService.settings.googleMapsConfig.libraries}&language=en`
    ).then(() => {
      console.log('Success');
    });
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    this.setLoginDisplay();
    
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      })
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount(){
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();
    this.userAccount = activeAccount
   
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
      if (!this.userService.userDetails.Id) {
        this.userService.aadDetails = accounts[0];
        this.userService.userEmail = accounts[0].username;
      }
    } else if (activeAccount) {
      this.userService.aadDetails = this.authService.instance.getActiveAccount();
      this.userService.userEmail = this.authService.instance.getActiveAccount().username;
    } else {
      this.userSub = this.userService.getUserInfo().subscribe(
        (userAAD: AAD) => {
          this.userService.aadDetails = userAAD;
        },
        (error) => {
         console.log(error);
        }
      );
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else {
      this.authService.logoutRedirect();
      localStorage.removeItem('DASHBOARD_CHOICE');
    }
  }


  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = name;
      document.getElementsByTagName('head')[0].appendChild(script);
      console.log('Script Loaded');
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    this.userSub?.unsubscribe();
  }

}
