import { Component, OnInit, HostListener } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { LovService } from '../services/lov.service';
import { QueryService } from '../services/query.service';
import { SORS } from '../sors';

@Component({
  selector: 'app-sors',
  templateUrl: './sors.component.html',
  styleUrls: ['./sors.component.scss'],
})
export class SorsComponent implements OnInit {
  selectedsors;
  newsors;
  working = false;

  sors: Array<any>;
  project$: Observable<any>;
  company$: Observable<any>;
  companyText: string;
  ocompanyText: string;
  owners$: Observable<any>;
  category$: Observable<any>;
  filterForm: UntypedFormGroup;
  fiscalyear =
    moment().quarter() === 1
      ? moment().subtract(1, 'year').format('YYYY')
      : moment().format('YYYY');
  screenWidth: number;
  selectedvalue: string

  constructor(
    private queryService: QueryService,
    private lovService: LovService
  ) {
    this.getScreenSize()
  }

  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.screenWidth = window.innerWidth;
    }

  ngOnInit(): void {
    console.log('sors:');
    this.lovService.setLookups('sselookups-cv', 'SORSLOOKUP');
    this.lovService.setCategoriesForTags('sselookups-cv', 'LOOKUPS')
    this.setLookups();
    this.filterform();
    this.refresh(this.filterForm);
  }



  filterform() {
    this.filterForm = new UntypedFormGroup({
      Fiscal: new UntypedFormControl(true),
      Ocompany: new UntypedFormControl(),
      Rcompany: new UntypedFormControl(),
      Project: new UntypedFormControl(),
      Date: new UntypedFormControl(),
      Category: new UntypedFormControl(),
    });
  }

  createSORS(): void {
    this.newsors = new SORS();

    console.log('createSORS');
  }

  refresh(filter) {
    console.log('refresh');

    console.log('filter', filter);
    this.sors = [];


    this.working = true;
    if (filter.get('Fiscal').value) {
      this.queryService
        .getAndBuild(
          ConfigService.settings.account,
          'sorsreport-events',
          this.fiscalyear,
          true
        )
        .subscribe((data) => {
          console.log(this.fiscalyear);
          console.log('data', data);
          // data.Audit.map((val)=>{this.audits.push(val)})
          this.sors = this.sors.concat(data.SORS);

          console.log('sors length', this.sors);
          this.sors = this.sors.sort((a, b) => {
            return b.SORS_DATE_INT - a.SORS_DATE_INT;
          });
          this.filterSORS(filter);
        });
      this.working = false;
    } else {
      // if (filter.get('FISCAL'))
      this.queryService
        .getList(ConfigService.settings.account, 'sorsreport-events')
        .subscribe(
          (res) => {
            for (const fiscal of res.blobs) {
              this.queryService
                .getAndBuild(
                  ConfigService.settings.account,
                  'sorsreport-events',
                  fiscal.name,
                  true
                )
                .subscribe((data) => {
                  console.log('this file');
                  // data.Audit.map((val)=>{this.audits.push(val)})
                  this.sors = this.sors.concat(data.Audit);
                  console.log('sors length' + this.sors.length);
                  this.sors = this.sors.sort((a, b) => {
                    return b.SORS_DATE_INT - a.SORS_DATE_INT;
                  });
                  this.filterSORS(filter);
                });
            }

            this.working = false;
          },
          (err) => {
            this.working = false;
            console.error('Failed', err);
            // this.errorDialogueService.openDialogue(JSON.stringify(err.error));
          }
        );
    }
  }

  setLookups = () => {
    this.company$ = this.lovService.company$;
    this.project$ = this.lovService.project$;
    this.category$ = this.lovService.catagories$;

  }

  filterSORS(filter) {
    const filterRcompany = filter.get('Rcompany').value;
    const filterOcompany = filter.get('Ocompany').value;

    const filterDate = filter.get('Date').value;

    console.log('filterRcompany:' + filterRcompany);
    this.sors = this.sors.filter((fil) => {
      return (
        (!filterRcompany || fil.SORS_RESPONSIBLE_COMPANY === filterRcompany) &&
        (!filterOcompany || fil.SORS_OBSERVER_COMPANY === filterOcompany) &&
        (!filterDate ||
          fil.SORS_DATE.substr(0, 10) ===
            moment(filterDate, 'YYYY-MM-DD').format('DD/MM/YYYY'))
      );
    });
  }

  clearSor = (sor) => {
    console.log('called', this.newsors)
    this.newsors = false;
  }

  updateSors = (sor) => {
    this.sors = [...this.sors,sor].sort((a, b) => {
      return b.SORS_DATE_INT - a.SORS_DATE_INT;
    })
  }

  selectedValue(event: any) {
    this.selectedvalue = event;
  }

  checkValue(formField) {
    if (!this.selectedvalue || this.selectedvalue !== this.filterForm.controls[formField].value) {
      return this.filterForm.controls[formField].setValue('');
    }
  }
}
