import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AttachmentsService {
  constructor(private http: HttpClient) {}

  
  saveAttachments = (container: string, path: string,  data: any, formData?: FormData) => {
    console.log("store",data);
    return this.http.put('saveAttachments', formData, {
      reportProgress: true,
      observe: 'events',
      params: new HttpParams()
        .set('account', ConfigService.settings.account)
        .set('container', container)
        .set('path', path),
    });
  };

  getAttachments = (container: string, attachments: string, Id: string, path: string = '', modelid: string) => {
    console.log('getting attachments ' + 'container:' + container + ' Id:' + Id + " attachmements", attachments + " path:" + path);
    return this.http.get('getAttachments', {
      reportProgress: true,
      observe: 'events',
      params: new HttpParams()
        .set('account', ConfigService.settings.account)
        .set('container', container)
        .set('Id', Id)
        .set('attachments', attachments)
        .set('path', path)
        .set('modelid',modelid ),
    });
  };
  populateForm(data: any, model: any) {
    const newForm: UntypedFormGroup = new UntypedFormGroup({});
    for (const [key, value] of Object.entries(model)) {
      if (typeof value !== 'object') {
        newForm.addControl(key, new UntypedFormControl(data[key] ? data[key] : '', Validators.required));
      } else if (Array.isArray(value)) {
        const formArray: UntypedFormArray = new UntypedFormArray([]);
        if (data[key]) {
          for (const el of data[key]) {
            formArray.push(this.populateForm(el, value[0] ? value[0] : el));
          }
        }
        newForm.addControl(key, formArray);
      } else {
        newForm.addControl(key, this.populateForm(data[key] ? data[key] : value, value));
      }
    }
    return newForm;
  }
  getAttachmentObject(objectId: string, type: string, modelpath: string = '', modelID: string = '') {
    console.log("getAndBuild:"+modelpath);
    let httpCall = {
      url: `getAndBuild`,
      options: {
        params: new HttpParams()
          .set('account', ConfigService.settings.account)
          .set('container', type + '-events')
          .set('blob', objectId)
          .set('skipdecrypt', 'true'),
      },
    };
    console.log('getting attachments blob');
    let data = this.http.get<any>(httpCall.url, httpCall.options).pipe(
      map((data) => {
        if (!modelpath) {
          return data.ATTACHMENT;
        } else {
          console.log("model "+modelpath)
          let attact=data[modelpath].filter((fil:any)=>{return fil["Id"]===modelID})
          console.log("attach",attact[0].ATTACHMENT)
          console.log("modelobject",data[modelpath].filter((fil:any)=>{return fil["Id"]===modelID}))
          return attact[0].ATTACHMENT
         // return data[modelpath].filter((fil:any)=>{return fil["Id"]===modelID})[0].ATTACHMENT;
        }

      })
    );
    console.log('data', data);
    return data;
  }
}

