import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { HttpClient } from '@angular/common/http';
import { BusinessEvent } from '../business-event';

@Injectable({
  providedIn: 'root',
})
export class CommandService {
  constructor(private httpClient: HttpClient) {}

  raise(subject, eventType, eventTopic, datain) {
    const businessEvent = new BusinessEvent();

    businessEvent.id = uuid();
    businessEvent.subject = subject;
    businessEvent.eventTime = new Date().toISOString();
    businessEvent.eventType = eventType;
    businessEvent.eventTopic = eventTopic;
    businessEvent.data = datain;

    delete businessEvent.metadataVersion;

    const httpCall = {
      url: `raiseEvent`,

      body: businessEvent,
    };

    return this.httpClient.put<any>(httpCall.url, httpCall.body).pipe(
      map((data) => {
        return [businessEvent];
      })
    );
  }
}
