export class AAD {
    odata? = '';
    businessPhones? = [''];
    displayName? = '';
    givenName? = '';
    id? = '';
    jobTitle? = '';
    mail? = '';
    mobilePhone? = '';
    officeLocation? = '';
    preferredLanguage? = '';
    surname? = '';
    userPrincipalName? = '';
    homeAccountId? = '';
    environment? = '';
    tenantId? = '';
    username? = '';
    localAccountId? = '';
    name? = '';
    idTokenClaims? = {};
  }

  export class User {
    Id = '';
    label = '';
    type = '';
    loglevel = '';
    USER_FIRST_NAME = '';
    USER_LAST_NAME = '';
    USER_EMAIL = '';
    USER_TELEPHONE = '';
    USER_LOCATION = '';
    USER_COSTCENTRE = '';
    USER_DIRECTORATE = '';
    USER_REPORTING_LEVEL_1 = '';
    USER_REPORTING_LEVEL_2 = '';
    USER_REPORTING_LEVEL_3 = '';
    USER_REPORTING_LEVEL_4 = '';
    USER_REPORTING_LEVEL_5 = '';
    USER_REPORTING_LEVEL_6 = '';
    USER_REPORTING_LEVEL_7 = '';
    USER_SUPERVISOR_NAME = '';
    USER_SUPERVISOR_EMAIL = '';
    USER_TAG = '';
    USER_ADMINISTRATOR = '';
    USER_BU_ADMINISTRATOR = '';
    USER_GLOBAL_ADMINISTRATOR = '';
    USER_ORGANIZATION_ID = '';
    USER_ORGANIZATION_NAME = '';
    USER_BUSINESS_REPORT_LEVEL_1 = '';
    USER_BUSINESS_REPORT_LEVEL_2 = '';
    USER_BUSINESS_REPORT_LEVEL_3 = '';
    USER_BUSINESS_REPORT_LEVEL_4 = '';
    USER_BUSINESS_REPORT_LEVEL_5 = '';
    USER_BUSINESS_REPORT_LEVEL_6 = '';
    USER_BUSINESS_REPORT_LEVEL_7 = '';
    USER_BUSINESS_REPORT_LEVEL_8 = '';
    USER_BUSINESS_REPORT_LEVEL_9 = '';
    USER_BUSINESS_REPORT_DESCRIPTION = '';
    USER_B2B_ONLY = true;
    Tickets: UserTickets[] = [new UserTickets()];
    Notification: UserNotification[] = [new UserNotification()];
  }

  export class UserTickets {
    Id = '';
  }
  
  export class UserNotification {
    Id = '';
  }