import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor(private formBuilder: UntypedFormBuilder) {}

  populateForm(blank, pathObj, Id): any {
    console.log(blank, pathObj, Id);
    const formObj: any = {};

    for (const key of Object.entries(blank)) {
      if (pathObj.chunk[key[0]] && typeof key[1] !== 'object') {
        formObj[key[0]] = pathObj.chunk[key[0]];
      } else {
        formObj[key[0]] = blank[key[0]];
      }
      if (typeof key[1] === 'object') {
        delete formObj[key[0]];
      }
      if (typeof pathObj.chunk[key[0]] === 'object') {
        formObj[key[0]] = formObj[key[0]];
      }
    }
    if (!formObj.Id) {
      formObj.Id = Id;
    }
    console.log(this.formBuilder.group(formObj))
    return this.formBuilder.group(formObj);
  }

  getPathObj(lastView, Id, pathroot, action = ''): any {
    let pathObj;

    console.log('pathroot*' + pathroot);

    if (!lastView) {
      const newId = uuid();
      pathObj = { path: pathroot + ':' + newId, chunk: { Id: newId } };
      pathObj.action = 'Created';
      pathObj.Id = newId;
    } else if (!Id) {
      const newId = uuid();
      pathObj = { path: pathroot + ':' + newId, chunk: { Id: newId } };
      pathObj.action = 'Created';
      pathObj.Id = newId;
    } else {
      pathObj = this.getPath(lastView, lastView.Id, Id, pathroot, 'user');

      pathObj.action = 'Updated';
      pathObj.Id = Id;
    }
    if (action) {
      pathObj.action = action;
    }

    return pathObj;
  }

  getPath(chunk, rootId, Id, path, obj): any {
    const pathroot = !path ? `data:${rootId}` : `${path}:${Id}`;
    console.log('pathroot:' + pathroot);

    if (chunk.Id === Id) {
      console.log('In Jea');
      return { path: pathroot, chunk };
    } else {
      if (chunk.Tab) {
        for (const tab of chunk.Tab) {
          const pathrtn = this.getPath(tab, rootId, Id, pathroot, 'Tab');

          if (pathrtn) {
            return pathrtn;
          }
        }
      }
    }
  }



  getEventBody(formCurrent, pathObj): any {
    let changes;
    if (pathObj.action === 'Deleted') {
      changes = { Id: formCurrent.get('Id').value };
    } else {
      changes = this.changesbf(pathObj.chunk, formCurrent.value);
    }

    const Id = pathObj.path.split('.')[0].split(':')[1];
    const pathsegs = pathObj.path.split('.');
    const eventBody = { Id, path: pathObj.path };
    if (pathsegs.length === 1) {
      for (const el of Object.entries(changes)) {
        eventBody[el[0]] = changes[el[0]];
      }
    } else if (pathsegs.length === 2) {
      eventBody[pathsegs[1].split(':')[0]] = [];
      eventBody[pathsegs[1].split(':')[0]].push(changes);
    } else if (pathsegs.length === 3) {
      eventBody[pathsegs[1].split(':')[0]] = [];
      eventBody[pathsegs[1].split(':')[0]].push({
        Id: pathsegs[1].split(':')[1],
        [pathsegs[2].split(':')[0]]: [],
      });

      eventBody[pathsegs[1].split(':')[0]][0][pathsegs[2].split(':')[0]].push(
        changes
      );
    }
    return eventBody;
  }

  changesbf(before, after): any {
    let diff;
    if (before) {
      diff = Object.keys(after).reduce((acc, curr) => {
        if (after[curr] !== before[curr]) {
          acc['Id'] = after['Id'];
          acc[curr] = after[curr];
        }
        return acc;
      }, {});
    } else {
      diff = after;
    }

    return diff;
  }

  reducer = (acc, curr) => {
    const tree = curr.data.path.split('.').map((val) => {
      return val.split(':');
    });

    const delAction =
      curr.eventType.split('.')[curr.eventType.split('.').length - 1] ===
      'Deleted'
        ? true
        : false;

    console.log(tree.length + ' tree', tree);

    if (tree.length === 1) {
      Object.keys(curr.data).map((val) => {
        console.log('val:' + val);
        acc[val] = curr.data[val];
      });
      delete acc.path;
      delete acc.level;
    } else {
      if (!acc[tree[1][0]]) {
        // no leaf

        acc[tree[1][0]] = curr.data[tree[1][0]];
      } else if (
        acc[tree[1][0]].filter((val) => {
          return val.Id === tree[1][1];
        }).length > 0
      ) {
        // change leaf
        const level1 = acc[tree[1][0]];
        if (tree.length < 3) {
          if (
            !delAction &&
            level1.filter((val3) => {
              return val3.Id === tree[1][1];
            }).length > 0
          ) {
            const hit = level1.filter((val3) => {
              return val3.Id === tree[1][1];
            })[0];
            Object.keys(curr.data[tree[1][0]][0]).map((val5) => {
              hit[val5] = curr.data[tree[1][0]][0][val5];
            });
          } else if (delAction) {
            acc[tree[1][0]] = level1.filter((vald) => {
              return vald.Id !== tree[1][1];
            });
          }
        } else {
          /* console.log(
            "level 2:" +
              JSON.stringify(
                acc[tree[1][0]].filter((val) => {
                  return val.Id === tree[1][1];
                })[0][tree[2][0]]
              ),
            null,
            2
          ); */
          const level2 = acc[tree[1][0]].filter((val) => {
            return val.Id === tree[1][1];
          })[0][tree[2][0]];

          if (level2) {
            if (tree.length < 4) {
              if (
                !delAction &&
                level2.filter((val3) => {
                  return val3.Id === tree[2][1];
                }).length > 0
              ) {
                const hit = level2.filter((val3) => {
                  return val3.Id === tree[2][1];
                })[0];
                Object.keys(curr.data[tree[1][0]][0][tree[2][0]][0]).map(
                  (val5) => {
                    hit[val5] = curr.data[tree[1][0]][0][tree[2][0]][0][val5];
                  }
                );
              } else if (delAction) {
                acc[tree[1][0]].filter((val) => {
                  return val.Id === tree[1][1];
                })[0][tree[2][0]] = level2.filter((vald) => {
                  return vald.Id !== tree[2][1];
                });
              } else {
                level2.push(curr.data[tree[1][0]][0][tree[2][0]][0]);
              }
            } else {
              const level3 = acc[tree[1][0]]
                .filter((val) => {
                  return val.Id === tree[1][1];
                })[0]
                [tree[2][0]].filter((val2) => {
                  return val2.Id === tree[2][1];
                })[0][tree[3][0]];
              if (level3) {
                if (tree.length < 5) {
                  if (
                    !delAction &&
                    level3.filter((val3) => {
                      return val3.Id === tree[3][1];
                    }).length > 0
                  ) {
                    const hit = level3.filter((val3) => {
                      return val3.Id === tree[3][1];
                    })[0];
                    Object.keys(
                      curr.data[tree[1][0]][0][tree[2][0]][0][tree[3][0]][0]
                    ).map((val5) => {
                      hit[val5] =
                        curr.data[tree[1][0]][0][tree[2][0]][0][tree[3][0]][0][
                          val5
                        ];
                    });
                  } else if (delAction) {
                    acc[tree[1][0]]
                      .filter((val) => {
                        return val.Id === tree[1][1];
                      })[0]
                      [tree[2][0]].filter((val2) => {
                        return val2.Id === tree[2][1];
                      })[0][tree[3][0]] = level3.filter((vald) => {
                      return vald.Id !== tree[3][1];
                    });
                  } else {
                    level3.push(
                      curr.data[tree[1][0]][0][tree[2][0]][0][tree[3][0]][0]
                    );
                  }
                } else {
                  const level4 = acc[tree[1][0]]
                    .filter((val) => {
                      return val.Id === tree[1][1];
                    })[0]
                    [tree[2][0]].filter((val2) => {
                      return val2.Id === tree[2][1];
                    })[0]
                    [tree[3][0]].filter((val3) => {
                      return val3.Id === tree[3][1];
                    })[0][tree[4][0]];
                  if (level4) {
                    if (tree.length < 6) {
                      if (
                        level4.filter((val3) => {
                          return val3.Id === tree[4][1];
                        }).length > 0
                      ) {
                        const hit = level4.filter((val3) => {
                          return val3.Id === tree[4][1];
                        })[0];
                        Object.keys(
                          curr.data[tree[1][0]][0][tree[2][0]][0][
                            tree[3][0]
                          ][0][tree[4][0]][0]
                        ).map((val5) => {
                          hit[val5] =
                            curr.data[tree[1][0]][0][tree[2][0]][0][
                              tree[3][0]
                            ][0][tree[4][0]][0][val5];
                        });
                      } else if (delAction) {
                        acc[tree[1][0]]
                          .filter((val) => {
                            return val.Id === tree[1][1];
                          })[0]
                          [tree[2][0]].filter((val2) => {
                            return val2.Id === tree[2][1];
                          })[0]
                          [tree[3][0]].filter((val3) => {
                            return val3.Id === tree[3][1];
                          })[0][tree[4][0]] = level4.filter((vald) => {
                          return vald.Id !== tree[4][1];
                        });
                      } else {
                        level4.push(
                          curr.data[tree[1][0]][0][tree[2][0]][0][
                            tree[3][0]
                          ][0][tree[3][0]][0]
                        );
                      }
                    }
                  } else {
                    acc[tree[1][0]]
                      .filter((val) => {
                        return val.Id === tree[1][1];
                      })[0]
                      [tree[2][0]].filter((val2) => {
                        return val2.Id === tree[2][1];
                      })[0]
                      [tree[3][0]].filter((val3) => {
                        return val3.Id === tree[3][1];
                      })[0][tree[4][0]] =
                      curr.data[tree[1][0]][0][tree[2][0]][0][tree[3][0]][0][
                        tree[4][0]
                      ];
                  }
                }
              } else {
                acc[tree[1][0]]
                  .filter((val) => {
                    return val.Id === tree[1][1];
                  })[0]
                  [tree[2][0]].filter((val2) => {
                    return val2.Id === tree[2][1];
                  })[0][tree[3][0]] =
                  curr.data[tree[1][0]][0][tree[2][0]][0][tree[3][0]];
              }
            }
          } else {
            acc[tree[1][0]].filter((val) => {
              return val.Id === tree[1][1];
            })[0][tree[2][0]] = curr.data[tree[1][0]][0][tree[2][0]];
          }
        }
      } else {
        acc[tree[1][0]].push(curr.data[tree[1][0]][0]);
      }
    }

    return acc;
  };

  getEventBodydyn(formCurrent, pathObj): any {
    let changes;
    if (pathObj.action === "Deleted") {
      changes = { Id: formCurrent.get("Id").value };
    } else {
      changes = this.changesbf(pathObj.chunk, formCurrent.value);
    }
    console.log("changes:", changes)
    console.log("pathObj:", pathObj)

    let Id = pathObj.path.split(".")[0].split(":")[1];
    let pathsegs = pathObj.path.split(".");
    let eventBody = { Id: Id, path: pathObj.path };
    if (pathsegs.length === 1) {
      for (let el of Object.entries(changes)) {
        eventBody[el[0]] = changes[el[0]];
      }
    } else if (pathsegs.length === 2) {
      eventBody[pathsegs[1].split(":")[0]] = [];
      eventBody[pathsegs[1].split(":")[0]].push(changes);
    } else if (pathsegs.length === 3) {
      eventBody[pathsegs[1].split(":")[0]] = [];
      eventBody[pathsegs[1].split(":")[0]].push({
        Id: pathsegs[1].split(":")[1],
        [pathsegs[2].split(":")[0]]: [],
      });
      eventBody[pathsegs[1].split(":")[0]][0][pathsegs[2].split(":")[0]].push(
        changes
      );
    } else if (pathsegs.length === 4) {
      eventBody[pathsegs[1].split(":")[0]] = [];
      eventBody[pathsegs[1].split(":")[0]].push({
        Id: pathsegs[1].split(":")[1],
        [pathsegs[2].split(":")[0]]: [{Id: pathsegs[2].split(":")[1],
        [pathsegs[3].split(":")[0]]:[]}],
            
      });
      



      eventBody[pathsegs[1].split(":")[0]][0][pathsegs[2].split(":")[0]][0][pathsegs[3].split(":")[0]].push(
        changes
      );
    } else {
      console.log("NEED TO GO DEEPER")
    }
    console.log("eventBody", eventBody)

    return eventBody;
  }
  getPathObjdyn(lastView, Id, pathroot, action = ""): any {
    let pathObj;

    if (!lastView) {
      let newId = uuid();
      pathObj = { path: pathroot + ":" + newId, chunk: { Id: newId } };
      pathObj.action = "Created";
      pathObj.Id = newId
    } else if (!Id) {
      let newId = uuid();
      pathObj = { path: pathroot + ":" + newId, chunk: { Id: newId } };
      pathObj.action = "Created";
      pathObj.Id = newId;
    } else {
      console.log("HT Lastview", lastView)

      pathObj = this.getPathdyn(lastView, lastView.Id, Id, pathroot, "user");
      console.log("HER pathObj", pathObj)


      pathObj.action = "Updated";
      if (pathObj.Id) {
        pathObj.Id = Id['ATTRIBUTE_VALUE'];
      }
    }
    if (action) {
      pathObj.action = action;
    }

    return pathObj;
  }
  populateFormdyn(blank, pathObj, Id): any {
    let formObj: any = { form: {}, detail: [] };
    console.log("VVVVVVV  building form", pathObj)
    for (let key of Object.entries(blank)) {
      if (pathObj.chunk[key[0]] && typeof pathObj.chunk[key[0]] != "object") {
        // console.log("ZZZZZZpathObj.blank[key[0]]['ATTRIBUTE_REQUIRED']")
        // console.log("key:",key[0])
        // console.log("blank",blank)
        console.log("using value")
        formObj.detail.push(blank[key[0]]);
        //console.log("value",pathObj.chunk[key[0]])
        // console.log("seen blank", (blank[key[0]]['ATTRIBUTE_VALUE'],['ATTRIBUTE_REQUIRED']?Validators.required:''))
        // console.log("")
        formObj.form[key[0]] = [pathObj.chunk[key[0]], (blank[key[0]]['ATTRIBUTE_REQUIRED'] ? Validators.required : '')];

      } else {
        // console.log("key[1]",key[1])
        // console.log ("from blank", blank[key[0]]['ATTRIBUTE_VALUE'])
        console.log("setting value",blank)
        formObj.detail.push(blank[key[0]]);
        if (pathObj.chunk[key[0]]) {
          formObj.form[key[0]] = [pathObj.chunk[key[0]]['ATTRIBUTE_VALUE'], (blank[key[0]]['ATTRIBUTE_REQUIRED'] ? Validators.required : '')];
        } else {
          console.log("using blank")

          formObj.form[key[0]] = [blank[key[0]]['ATTRIBUTE_VALUE'], (blank[key[0]]['ATTRIBUTE_REQUIRED'] ? Validators.required : '')];
        }

      }

    }

    return formObj

  }
  getPathdyn(chunk, rootId, Id, path, obj): any {

    let pathroot
    if (typeof (Id) === "object") {
      pathroot = !path ? `data:${rootId}` : `${path}:${Id["ATTRIBUTE_VALUE"]}`;
      if (chunk.Id["ATTRIBUTE_VALUE"] === Id["ATTRIBUTE_VALUE"]) {
        return { path: pathroot, chunk: chunk };
      }
    } else {
      pathroot = !path ? `data:${rootId}` : `${path}:${Id}`;
    }




    if (chunk.Id === Id) {
      return { path: pathroot, chunk: chunk };
    }

  }

}
