import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { AAD, User } from '../models/user-classes';
import {  retry } from 'rxjs/operators';


@Injectable({providedIn: 'root'})
export class UserService {

  constructor(private http: HttpClient) { }

  userEmail: string;
  private readonly _aadDetails: BehaviorSubject<AAD> = new BehaviorSubject<AAD>(new AAD());
  readonly aadDetails$: Observable<any> = this._aadDetails.asObservable();
  private readonly _userDetails: BehaviorSubject<User> = new BehaviorSubject<User>(new User());
  readonly userDetails$: Observable<User> = this._userDetails.asObservable();


  getUserInfo = (): Observable<any> => {
    return this.http.get('https://graph.microsoft.com/v1.0/me').pipe(retry(3));
  };
  
  get userDetails(): User {
    return this._userDetails.getValue();
  }

  set userDetails(userdetails: User) {
    this._userDetails.next(userdetails);
  }

  set aadDetails(aad: AAD) {
    this._aadDetails.next(aad);
  }
  get aadDetails(): AAD {
    return this._aadDetails.getValue();
  }

}
