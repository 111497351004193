import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
  MsalModule,
  MsalService,
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalRedirectComponent,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import {
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  IPublicClientApplication,
  LogLevel,
} from '@azure/msal-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { LoginFailedComponent } from './login-failed.component';
import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';;
import { SorsComponent } from './sors/sors.component';
import { SorsPanelComponent } from './sors-panel/sors-panel.component';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { ConfigService } from './services/config.service';
import { HttpBackendClient } from './services/httpBackendClient';
import { ApiPrefixInterceptor } from './http/api-prefix.interceptor';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AddFileComponent } from './add-file/add-file.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MaterialModule } from './material.module';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { SorsSearchComponent } from './sors-search/sors-search.component';
import { SorsListComponent } from './sors-list/sors-list.component';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { Loader } from '@googlemaps/js-api-loader';
//import { ImagePreviewComponent } from './image-preview-dialog.component';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;


  export function loggerCallback(logLevel: LogLevel, message: string) {
    // console.log(message); // LOGGER FOR MSAL
  }

  export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
      auth: ConfigService.settings.auth,
      cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage, // >>>> Update
        storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
      },
      system: {
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false
        }
      }
    });
  }

  export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>(
      ConfigService.settings.protectedResourceMap
    );
  
    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap,
    };
  }
  

  export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['user.read'],
      },
      loginFailedRoute: '/login-failed'
    };
  }

export function initializeApp(configService: ConfigService) {
  return () => configService.getsettings();
}

export function GoogleMapsFactory() {
  return new Loader({
    apiKey: ConfigService.settings.googleMapsConfig.key,
    libraries: ['places'],
  });
}

@NgModule({
  declarations: [
    AppComponent,
    LoginFailedComponent,
    HomeComponent,
    SorsComponent,
    SorsPanelComponent,
    SearchFilterPipe,
    DateFormatPipe,
    AddFileComponent,
    DashboardComponent,
    ImagePreviewComponent,
    SorsSearchComponent,
    SorsListComponent,
    SorsPanelComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    GoogleMapsModule,
    NgApexchartsModule,
    MaterialModule,
    MaterialFileInputModule,
    RouterModule,
    NgxGpAutocompleteModule,
    MsalModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true,
    },  
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },

    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: Loader,
      useFactory: GoogleMapsFactory,
    },
    MsalGuard,
    MsalService,
    HttpBackendClient,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
