<mat-progress-bar *ngIf="working" mode="indeterminate" color="primary"></mat-progress-bar>

<section [ngClass]="screenWidth > 1200 ? 'row' : 'col'">
  <div class="container">
    <button mat-raised-button (click)="createSORS()" color="primary" style="margin-top: 20px;padding: 24px;font-size: 17px;">
      New Safety Observation
    </button>
    <div *ngIf="newsors">
      <app-sors-panel [company$]="company$" [category$]="category$" [project$]="project$" [SORS]="newsors"
        (eventRaised)="updateSors($event)" (closebtn)="clearSor($event)"></app-sors-panel>
    </div>
  </div>
</section>