export class SORS {
    Id = '';
    SORS_DATE = '';
    SORS_DATE_INT = '';
    SORS_OBSERVER = '';
    SORS_OBSERVER_COMPANY = '';
    SORS_ADDRESS = '';
  SORS_GIS = '';
    SORS_RESPONSIBLE_COMPANY = '';
    SORS_PROJECT = '';
    SORS_OBS_CATEGORY = '';
    SORS_OBSERVATION = '';
    SORS_IMMEDIATE_ACTION = '';
  SORS_FISCALYEAR = '';
  SORS_FISCALQUARTER = '';
  SORS_TITLE = '';
  SORS_TYPE = '';
  SORS_TAG_SEARCH = '';
  SOS_STATUS = '';
}

