import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { LookupLevel0, LookupLevel1, LookupLevel2 } from '../lookups';
import { ConfigService } from './config.service';
import { QueryService } from './query.service';

export class LookUpContainer {
  constructor(data: any) {
    this.bhSub.next(data);
  }
  private readonly bhSub = new BehaviorSubject([]);
  readonly obs = this.bhSub.asObservable();
}

@Injectable({
  providedIn: 'root',
})
export class LovService {
  constructor(private http: HttpClient, private queryService: QueryService) {}
  lookup: any = {};
  private readonly _alllookups: BehaviorSubject<any> = new BehaviorSubject<any>(
    []
  );
  readonly alllookups$: Observable<any> = this._alllookups.asObservable();

  set alllovs(lovs: any) {
    this._alllookups.next([lovs]);
  }
  get alllovs() {
    return this._alllookups.getValue();
  }

  private readonly ALLKEYWORDS = new BehaviorSubject<LookupLevel2[]>([]);
  readonly allkeywords$ = this.ALLKEYWORDS.asObservable();

  get allKeywords() {
    return this.ALLKEYWORDS.getValue();
  }

  private readonly SORS_PROJECT: BehaviorSubject<LookupLevel2[]> =
    new BehaviorSubject<LookupLevel2[]>([]);
  readonly project$: Observable<LookupLevel2[]> =
    this.SORS_PROJECT.asObservable();

  get project() {
    return this.SORS_PROJECT.getValue();
  }

  private readonly SORS_OBS_CATEGORY: BehaviorSubject<LookupLevel2[]> =
    new BehaviorSubject<LookupLevel2[]>([]);
  readonly category$: Observable<LookupLevel2[]> =
    this.SORS_OBS_CATEGORY.asObservable();

  get category() {
    return this.SORS_OBS_CATEGORY.getValue();
  }

  private readonly SORS_OBSERVER_COMPANY: BehaviorSubject<LookupLevel2[]> =
    new BehaviorSubject<LookupLevel2[]>([]);
  readonly company$: Observable<LookupLevel2[]> =
    this.SORS_OBSERVER_COMPANY.asObservable();

  get company() {
    return this.SORS_OBSERVER_COMPANY.getValue();
  }
  private readonly FREQUENCY: BehaviorSubject<LookupLevel2[]> =
    new BehaviorSubject<LookupLevel2[]>([]);
  readonly frequency$: Observable<LookupLevel2[]> =
    this.FREQUENCY.asObservable();
  get frequency() {
    return this.FREQUENCY.getValue();
  }

  private readonly BUSINESS_UNIT: BehaviorSubject<LookupLevel2[]> =
    new BehaviorSubject<LookupLevel2[]>([]);
  readonly business$: Observable<LookupLevel2[]> =
    this.BUSINESS_UNIT.asObservable();
  get business() {
    return this.BUSINESS_UNIT.getValue();
  }

  private readonly AUDIT_TYPE: BehaviorSubject<LookupLevel2[]> =
    new BehaviorSubject<LookupLevel2[]>([]);
  readonly type$: Observable<LookupLevel2[]> = this.AUDIT_TYPE.asObservable();
  get type() {
    return this.AUDIT_TYPE.getValue();
  }

  private readonly CATAGORIES: BehaviorSubject<LookupLevel2[]> =
    new BehaviorSubject<LookupLevel2[]>([]);
  readonly catagories$: Observable<LookupLevel2[]> =
    this.CATAGORIES.asObservable();

  set catagories(arr: any) {
    this.CATAGORIES.next([...this.CATAGORIES.getValue(), ...arr]);
  }

  list = ['PEOPLE', 'ENVIRONMENT', 'ASSET', 'REPUTATION'];
  keywordList = [
    'INJURYKEYWORDS',
    'ASSETKEYWORDS',
    'RTCKEYWORDS',
    'ENVKEYWORDS',
    'REPUTATIONKEYWORDS',
    'INJURYLIST',
  ];
  others = ['SORS_OBSERVER_COMPANY', 'SORS_PROJECT', 'SORS_OBS_CATEGORY'];
  getList = (container: string): Observable<any> => {
    return this.http.get('getList', {
      params: new HttpParams()
        .set('account', 'rodevshe')
        .set('container', container),
    });
  };

  setLookups = (container: string, blob: string) => {
    this.http
      .get('getBlob', {
        params: new HttpParams()
          .set('account', ConfigService.settings.account)
          .set('container', container)
          .set('blob', blob),
      })
      .subscribe((res: any) => {
        this.alllovs = res.LEVEL1;
        res.data.LEVEL1.forEach((lookups: LookupLevel1) => {
          this.lookup[lookups.LEVEL1_ATTRIBUTE1] = new LookUpContainer(
            lookups.LEVEL2
          );
          if (this.list.includes(lookups.LEVEL1_ATTRIBUTE1)) {
            this.catagories = lookups;
            return;
          }
          if (this.others.includes(lookups.LEVEL1_ATTRIBUTE1)) {
            this[lookups.LEVEL1_ATTRIBUTE1].next(lookups.LEVEL2);
          }
        });
      });
  };


  setCategoriesForTags = (container, blob) => {
    this.http
      .get('getBlob', {
        params: new HttpParams()
          .set('account', ConfigService.settings.account)
          .set('container', container)
          .set('blob', blob),
      }).pipe((map((res: any) => res.data.LEVEL1.filter((lookups: LookupLevel1) => this.list.includes(lookups.LEVEL1_ATTRIBUTE1
        ))))).subscribe((res: any) => {
          const newArr = [].concat.apply([], res.map(data => data.LEVEL2.filter(list => list.LEVEL2_ATTRIBUTE4 === 'Hazard' || list.LEVEL2_ATTRIBUTE5 === 'Positive')))
          this.catagories = newArr
      })
  }
}

