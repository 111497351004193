import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormBuilderService {
  populateForm(data: any, model: any) {


    const newForm: UntypedFormGroup = new UntypedFormGroup({});
    for (const [key, value] of Object.entries(model)) {
      if (typeof value !== 'object') {
        
        newForm.addControl(key, new UntypedFormControl(data[key] ? data[key] : '', Validators.required));
      } else if (Array.isArray(value)) {
        
        const formArray: UntypedFormArray = new UntypedFormArray([]);
        if (data[key]) {
          for (const el of data[key]) {
            formArray.push(this.populateForm(el, value[0] ? value[0] : el));
          }
        }
        newForm.addControl(key, formArray);
      } else {
        newForm.addControl(key, this.populateForm(data[key] ? data[key] : value, value));
      }
    }
    return newForm;
  }
}
