<div class="container">
    <form [formGroup]="filterForm" *ngIf="filterForm" [ngClass]="screenWidth > 800 ? 'desktop' : 'mobile'">
        <div class="button-container">
            <mat-checkbox style="color: grey" formControlName="Fiscal">
                FY
                {{ fiscalyear }}
            </mat-checkbox>
        </div>


        <mat-form-field appearance="outline">
          <mat-label> Keyword Category </mat-label>
          <input matInput formControlName="Tag" [matAutocomplete]="tag"
              (blur)="checkValue('Tag')" />
          <mat-autocomplete #tag="matAutocomplete" (optionSelected)="selectedValue($event.option.value)">
              <mat-option *ngFor="
            let category of this.category$ | async | searchFilter: tagText
          " [value]="category.LEVEL2_ATTRIBUTE2">
                  {{ category.LEVEL2_ATTRIBUTE2 }}</mat-option>
          </mat-autocomplete>
          <mat-hint align="end"> Start typing </mat-hint>
      </mat-form-field>



        <mat-form-field appearance="outline">
            <mat-label> Project </mat-label>
            <input matInput formControlName="Project" [matAutocomplete]="project"
                (blur)="checkValue('Project')" />
            <mat-autocomplete #project="matAutocomplete" (optionSelected)="selectedValue($event.option.value)">
                <mat-option *ngFor="
              let project of this.project$ | async | searchFilter: projectText
            " [value]="project.LEVEL2_ATTRIBUTE2">
                    {{ project.LEVEL2_ATTRIBUTE2 }}</mat-option>
            </mat-autocomplete>
            <mat-hint align="end"> Start typing </mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Observer Company</mat-label>
            <input matInput formControlName="Ocompany" [matAutocomplete]="Ocompanyauto"
                (blur)="checkValue('Ocompany')" />
            <mat-autocomplete #Ocompanyauto="matAutocomplete" (optionSelected)="selectedValue($event.option.value)">
                <mat-option *ngFor="
              let ocompany of this.company$ | async | searchFilter: ocompanyText
            " [value]="ocompany.LEVEL2_ATTRIBUTE2">
                    {{ ocompany.LEVEL2_ATTRIBUTE2 }}</mat-option>
            </mat-autocomplete>
            <mat-hint align="end"> Start typing </mat-hint>
        </mat-form-field>

 
        <mat-form-field appearance="outline">
            <mat-label>Date</mat-label>
            <input matInput type="date" formControlName="Date" />
        </mat-form-field>


        <div class="button-container">
            <button mat-button (click)="refresh(filterForm)" color="primary">
                <mat-icon>refresh</mat-icon>
                Refresh
            </button>
        </div>
    </form>

    <div [ngClass]="screenWidth > 1400 ? 'desktop' : 'mobile-list'">
        <div *ngFor="let asors of sors" class="list">
            <!-- <button
          mat-button
          *ngIf="screenWidth > 900"
          (click)="selectedsors = asors"
          class="desktop-btn"
        >
          {{ asors.SORS_TITLE }}
        </button> -->
            <button mat-raised-button color="primary" (click)="selectedsors = asors" class="mobile-btn">
               {{ asors?.SORS_TITLE }}
            </button>

        <mat-accordion class="example-headers-align" *ngIf="asors && selectedsors === asors">
                <mat-expansion-panel   [expanded]="panelOpenState"
                #mep="matExpansionPanel"
                >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ asors.SORS_DATE }}
                    </mat-panel-title>
                    <mat-panel-description>
                        Safety Observations Details
                        <mat-icon color="accent" >feed</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="center">
                     <app-sors-list  [company$]="company$" [category$]="category$"
                    [project$]="project$" [SORS]="selectedsors">
                </app-sors-list>
                </div>
               
                <mat-action-row>
                    <button mat-raised-button  color="warn" (click)="mep.toggle()"> 
                        <mat-icon>
                                unfold_less
                            </mat-icon>
                        Close 
                    </button>
                </mat-action-row>
                </mat-expansion-panel>
            </mat-accordion>

        </div>
    </div>
</div>


<!-- <div>
  {{filterForm.value | json}}
</div> -->