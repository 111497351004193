import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CommandService } from '../services/command.service';
import { EventService } from '../services/event.service';
import { LovService } from '../services/lov.service';
import { QueryService } from '../services/query.service';
import { SORS } from '../sors';
import { distinctUntilChanged, debounceTime, map, startWith } from 'rxjs/operators';
import { Output } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { ConfigService } from '../services/config.service';
import * as moment from 'moment';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilderService } from '../services/form-builder.service';

@Component({
  selector: 'app-sors-list',
  templateUrl: './sors-list.component.html',
  styleUrls: ['./sors-list.component.scss']
})
export class SorsListComponent implements OnInit {
  @Input() SORS;
  SORSFull;
  lastView;
  formCurrent: UntypedFormGroup;
  pathObj: any;
  @Input() company$: Observable<any>;
  companyText: string;
  rcompany$: Observable<any>;
  rcompanyText: string;
  @Input() project$: Observable<any>;
  projectText: string;
  @Input() category$: Observable<any>;
  categories: any;
  categoryText: string;
  owners$: Observable<any>;
  ownerText: string;
  frequency$: Observable<any>;
  freqText: string;
  business$: Observable<any>;
  businessText: string;
  type$: Observable<any>;
  typeText: string;
  formSubscription: Subscription;
  currentDate;
  files: Array<any>

  // tags
  observationText: string;
  observationSubscription: Subscription;

  tagsRemoved: any = [];
  _tagsFound = new BehaviorSubject<string[]>([]);
  tagsFound$: Observable<string[]> = this._tagsFound.asObservable();

  typeSubscription: Subscription;
  tagsText: string;

  keys: any = [];
  hazardCats: any[] = [];
  positiveCats: any[] = [];

  // chips
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  submitted = false;

  @Output() eventRaised: EventEmitter<any> = new EventEmitter<any>();
  @Output() closebtn: EventEmitter<any> = new EventEmitter<any>()
  working = false;

  // google
  center;
  zoom = 10;
  map = false;
  infoContent = {
    placeName: '',
  };
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('somemarker') mark: MapMarker;
  @ViewChild(GoogleMap, { static: false }) gmap: any;
  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;

  marker = {
    position: {
      lat: 56.396999,
      lng: -3.437,
    },
    options: {
      draggable: true,
    },
    info: '',
  };
  options: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
    zoomControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 1,
  };
  pathroot

  selectedvalue: string

  ngOnInit() {
    // console.log("SORS",this.SORS)
    // this.pathroot="data:"+this.SORS.Id
    this.category$.subscribe((cats) => {
      this.categories = cats.map((cat) => {
        if (
          cat.LEVEL2_ATTRIBUTE7 &&
          typeof cat.LEVEL2_ATTRIBUTE7 === 'string' &&
          cat.LEVEL2_ATTRIBUTE7 !== ''
        ) {
          cat.LEVEL2_ATTRIBUTE7 = cat.LEVEL2_ATTRIBUTE7.split(',').map(
            (el: string) => el.toUpperCase()
          );
        }
        return cat;
      });
      this.positiveCats = this.categories.filter(
        (cat) => cat.LEVEL2_ATTRIBUTE5 === 'Positive'
      );
      this.hazardCats = this.categories.filter(
        (cat) => cat.LEVEL2_ATTRIBUTE4 === 'Hazard'
      );
    });

  }

  constructor(
    private commandService: CommandService,
    private eventService: EventService,
    private queryService: QueryService,
    private formBuilderService: FormBuilderService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log('project$', this.project$);
    this.working = true;

    if (this.SORS.name) {
      console.log('from name');
      this.queryService
        .getAndBuild(
          ConfigService.settings.account,
          'audit-events',
          this.SORS.name,
          false
        )
        .subscribe((res) => {
          console.log('audits', res);
          this.SORS = res;
          this.fillForm();
          this.handleFormChanges();
          this.working = false;
        });
    } else if (this.SORS.Id) {
      console.log('getting from Id');
      this.queryService
        .getAndBuild(
          ConfigService.settings.account,
          'sors-events',
          this.SORS.Id,
          false
        )
        .subscribe((data) => {
         // console.log('Attachments:'+ data.ATTACHMENT.length);
          this.SORS = data;
          this.fillForm();
          this.handleFormChanges();
          this.working = false;
        });
    } else {
      console.log('Blank Audit', new SORS());
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.marker.position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.getReverseGeoCodingData(
          position.coords.latitude,
          position.coords.longitude
        );
      });
      this.fillForm();
      this.handleFormChanges();
      this.working = false;
    }
  }

  fillForm(): void {
    this.lastView = this.SORS;

    console.log('SORS', this.SORS);

    const pathroot = 'data';
    this.pathObj = this.eventService.getPathObj(
      this.SORS,
      this.SORS.Id,
      pathroot
    );
    console.log('this.pathObj', this.pathObj);
    this.pathroot=this.pathObj.path
    this.formCurrent = this.formBuilderService.populateForm(
      this.pathObj.chunk,
      new SORS(),
    );


    let currentDate: Date;
    if (!this.SORS.SORS_DATE) {
      currentDate = new Date();
    } else {
      const momentdate = moment(this.SORS.SORS_DATE, 'DD/MM/YYYY HH:mm');

      currentDate = new Date(momentdate.toISOString());
    }

    this.formCurrent
      .get('SORS_DATE')
      .setValue(currentDate.toISOString().slice(0, 16));
  }



  openMap = () => {
    if (!this.map) {
      this.map = true;
    } else {
      this.map = false;
    }
  };

  raise() {

    const eventBody = this.eventService.getEventBody(
      this.formCurrent,
      this.pathObj
    );

    const checkdate = moment(eventBody.SORS_DATE);
    eventBody.SORS_DATE = moment(eventBody.SORS_DATE).format(
      'DD/MM/YYYY HH:mm'
    );
    console.log('eventBody.SORS_DATE' + eventBody.SORS_DATE);

    eventBody.SORS_FISCALYEAR =
    moment(checkdate).quarter() === 1
      ? moment(checkdate).subtract(1, 'year').format('YYYY')
      : moment(checkdate).format('YYYY');
  eventBody.SORS_FISCALQUARTER =
    moment(checkdate).quarter() === 1
      ? moment(checkdate).subtract(1, 'year').format('YYYY') + '/4'
      : moment(checkdate).format('YYYY') +
        '/' +
        (moment(checkdate).quarter() - 1);

    const title =
      (eventBody.SORS_PROJECT
        ? eventBody.SORS_PROJECT
        : this.SORS.SORS_PROJECT) +
      '.' +
      (eventBody.SORS_OBSERVER_COMPANY
        ? eventBody.SORS_OBSERVER_COMPANY
        : this.SORS.SORS_OBSERVER_COMPANY) +
      '.' +
      (eventBody.SORS_RESPONSIBLE_COMPANY
        ? eventBody.SORS_RESPONSIBLE_COMPANY
        : this.SORS.SORS_RESPONSIBLE_COMPANY) +
      '.' +
      (eventBody.SORS_OBS_CATEGORY
        ? eventBody.SORS_OBS_CATEGORY
        : this.SORS.SORS_OBS_CATEGORY) +
      '.' +
      (eventBody.SORS_DATE ? eventBody.SORS_DATE : this.SORS.SORS_DATE);
      eventBody.SORS_TITLE = title
    this.formCurrent.get('SORS_TITLE').setValue(title)
    if (eventBody.SORS_DATE) {
      eventBody.SORS_DATE_INT = moment(
        eventBody.SORS_DATE,
        'DD/MM/YYYY HH:mm'
      ).unix();
    }
    eventBody.subject = 'SORS: ' + eventBody.SORS_TITLE;
    eventBody.url = 'http://localhost:4200/sors/' + eventBody.Id;
    eventBody.urllabel = 'Open SORS ' + eventBody.SORS_TITLE;

    console.log('user eventBody:', eventBody);

    this.commandService
      .raise(
        `sors.` + eventBody.Id,
        `${ConfigService.settings.projectCode}.${ConfigService.settings.projectPath}.${ConfigService.settings.projectLocation}.${this.pathObj.action}`,
        `events`,
        eventBody
      )
      .subscribe((data) => {
        console.log('Raised:', data);
        if (!this.lastView) {
          this.lastView = data[0].data;
        } else {
          this.lastView = this.eventService.reducer(this.lastView, data[0]);
        }
        this.openSnackBar();
        this.eventRaised.emit(this.formCurrent.value)
        this.submitted = true;
      });
  }

  displayTagsOnLoad = () => {
    const list = this.formCurrent.value.SORS_TYPE === 'Hazard' ? this.hazardCats : this.positiveCats
    const currTags = this.formCurrent.get('SORS_OBS_CATEGORY').value
    const tags = []
    currTags.forEach(tag => tags.push(list.find(item => item.LEVEL2_ATTRIBUTE2 === tag)))
    if (this._tagsFound.getValue().length > 0 && tags.length > 0) {
      this._tagsFound.next([...this._tagsFound.getValue(), ...tags])
    } else
    if (tags.length > 0 && this._tagsFound.getValue().length < 1) {
      this._tagsFound.next([...tags])
    }
  }


  handleFormChanges = () => {
    if (this.formCurrent.value.SORS_OBS_CATEGORY) {
      this.displayTagsOnLoad()
    }


    this.formSubscription = this.formCurrent.valueChanges
      .pipe(debounceTime(200))
      .subscribe((changes) => {
        this.companyText = changes.SORS_OBSERVER_COMPANY;
        this.rcompanyText = changes.SORS_RESPONSIBLE_COMPANY;
        this.projectText = changes.SORS_PROJECT;
        this.categoryText = changes.SORS_OBS_CATEGORY;
        this.typeText = changes.AUDIT_TYPE;
        this.submitted = changes.SOR_STATUS ? true : false;
      });

    this.observationSubscription = this.formCurrent
      .get('SORS_OBSERVATION')
      .valueChanges.pipe(startWith(this.formCurrent.value.SORS_OBSERVATION), debounceTime(200))
      .subscribe((changes: any) => {
            const foundwords: any = [];
        const list = this.formCurrent.value.SORS_TYPE === 'Hazard' ? this.hazardCats : this.positiveCats
            this.observationText = changes;
            list.forEach((obj: any) => {
              obj.LEVEL2_ATTRIBUTE7 &&
                obj.LEVEL2_ATTRIBUTE7.forEach((el: any) => {
                  if (
                    this.observationText
                      .toLowerCase()
                      .match(new RegExp(el.toLowerCase()))
                  ) {
                    foundwords.push([
                      this.observationText
                        .toLowerCase()
                        .match(new RegExp(el.toLowerCase())),
                      obj,
                    ]);
                  }
                });
            });
            foundwords
              .filter((el: any) => el[0][0] !== '')
              .forEach((el: any) => {
                if (
                  this.checkForDuplicates(el[1]) === -1 &&
                  !this.tagsRemoved.find((tag:any) => tag.LEVEL2_ATTRIBUTE2 === el[1].LEVEL2_ATTRIBUTE2)
                ) {
                  this._tagsFound.next([...this._tagsFound.getValue(), el[1]]);
                  this.addToFormField();
                }
              });
            this.observationText = '';
          });
  };

  addToFormField = () => {
    this.formCurrent.patchValue({
      SORS_OBS_CATEGORY: this._tagsFound
        .getValue()
        .map((el: any) => el.LEVEL2_ATTRIBUTE2),
    });
  };

  checkForDuplicates = (obj: any) => {
    return this._tagsFound.getValue().findIndex((word:any) => word.LEVEL2_ATTRIBUTE2 === obj.LEVEL2_ATTRIBUTE2);
  };
  ngOnDestroy(): void {
    this.formSubscription.unsubscribe();
  }

  handleAddressChange(address: any) {
    this.center = {
      lat: address.geometry.location.lat(),
      lng: address.geometry.location.lng(),
    } as google.maps.MapOptions;

    if (this.map) {
      this.openInfo(this.mark, address.formatted_address);
    }
    this.formCurrent.get('SORS_ADDRESS').setValue(address.formatted_address);
    this.formCurrent.get('SORS_GIS').setValue(JSON.stringify(this.center));

    this.zoom = 15;
  }

  dragEnd($event) {
    this.formCurrent
      .get('SORS_GIS')
      .setValue(JSON.stringify(this.marker.position));
    this.getReverseGeoCodingData($event.latLng.lat(), $event.latLng.lng());
  }

  mapClick($event) {
    this.center = {
      lat: $event.latLng.lat(),
      lng: $event.latLng.lng(),
    };
    this.marker = {
      position: {
        lat: $event.latLng.lat(),
        lng: $event.latLng.lng(),
      },
      options: {
        draggable: true,
      },
      info: `lat:${$event.latLng.lat()},lng:${$event.latLng.lng()}`,
    };
    this.getReverseGeoCodingData($event.latLng.lat(), $event.latLng.lng());
  }

  getReverseGeoCodingData = (lat: any, lng: any) => {
    const latLng = new google.maps.LatLng(lat, lng);
    const geo = new google.maps.Geocoder();
    geo.geocode({ location: latLng }, (result) => {
      this.formCurrent
        .get('SORS_ADDRESS')
        .setValue(result[0].formatted_address);
      if (this.map) {
        this.openInfo(this.mark, result[0].formatted_address);
      }
    });
  };

  removeTag = (tag: any) => {
    const duplicateArr = [...this._tagsFound.getValue()];
    const index = duplicateArr.indexOf(tag);
    if (index > -1) {
      this.tagsRemoved.push(tag);
      duplicateArr.splice(index, 1);
      this._tagsFound.next(duplicateArr);
      this.removeFromFormField();
      this.tagsText = '';
    }
  };

  removeFromFormField = () => {
    this.formCurrent.patchValue({
      TICKET_TAGS: this._tagsFound
        .getValue()
        .map((el: any) => el.LEVEL2_ATTRIBUTE2),
    });
  };

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value && !this.tagsRemoved.includes(value.toLowerCase())) {
      this._tagsFound.next([...this._tagsFound.getValue(), value]);
      this.addToFormField(

      );
    }
    event.chipInput.inputElement.value = '';
  }

  selectTag = (event: any) => {
    const tag = event.option.value;
    const duplicateArr = this._tagsFound.getValue();
    if (
      this.checkForDuplicates(tag) ===
      -1 /* && !this.tagsRemoved.includes(tag) */
    ) {
      this._tagsFound.next([...duplicateArr, tag]);
      this.addToFormField();
      this.tagsText = '';
    }
    this.tagInput.nativeElement.value = '';
  };

  openInfo(marker: MapMarker, content: any) {
    this.infoContent = content;
    this.info.open(marker);
  }

  openSnackBar() {
    this._snackBar.open('Sor Updated', 'Ok', {
      duration: 2000,
    });
  }

  selectedValue(event: any) {
    this.selectedvalue = event;
  }

  checkValue(formField) {
    if (!this.selectedvalue || this.selectedvalue !== this.formCurrent.controls[formField].value) {
      return this.formCurrent.controls[formField].setValue('');
    }
  }
}

