import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})


export class SearchFilterPipe implements PipeTransform {

  transform(list: any[], ...args: any[]): any {
    if (args[1] === 'set') {
      return list.filter((item: any) => item.search(new RegExp(args[0], 'i')) > -1);
    }
    if (args[1] === 'obj') {
      return list.filter((item: string) => item.search(new RegExp(args[0].searchText, 'i')) > -1);
    }
    if (args[1] === 'userLov') {
      return list.filter((item) => item.LEVEL2_ATTRIBUTE3.search(new RegExp(args[0], 'i')) > -1);
    }
    if (args[1] === 'hazards') {
      console.log(list, args);
      return list.filter((item) => item.LEVEL2_ATTRIBUTE2.search(new RegExp(args[0], 'i')) > -1);
    }
    return list.filter((item) => item.LEVEL2_ATTRIBUTE2.search(new RegExp(args[0], 'i')) > -1);
  }

}



