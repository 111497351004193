import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { ChartComponent } from 'ng-apexcharts';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ChartOptions, Sors } from '../models/interface';
import { DashboardService } from './dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {

  selection = this.fb.group({
    One: false,
    Two: false,
    Three: false,
    Four: false,
  });


  graphs: any[] = [
    {
      tag: 'One',
      name: 'Project - Positive Observation',
      completed: false,
      color: 'primary',
      icon: 'bar_chart',
    },
    {
      tag: 'Two',
      name: 'Project - Hazard',
      completed: false,
      color: 'primary',
      icon: 'bar_chart',
    },
    {
      tag: 'Three',
      name: 'Category - Positive Observation',
      completed: false,
      color: 'primary',
      icon: 'bar_chart',
    },
    {
      tag: 'Four',
      name: 'Category - Hazard',
      completed: false,
      color: 'primary',
      icon: 'bar_chart',
    },
  ];

  @ViewChild('chart', { static: false }) chart: ChartComponent;

  public common: Partial<ChartOptions>;
  public projectPositive: Partial<ChartOptions>;
  public obsPositiveCategory: Partial<ChartOptions>;
  public projectHazard: Partial<ChartOptions>;
  public obsHazardCategory: Partial<ChartOptions>;




  fiscalyear =
    moment().quarter() === 1
      ? moment().subtract(1, 'year').format('YYYY')
      : moment().format('YYYY');

  projPositiveCategories: string[] = [];
  projPositiveSeries: any[] = [];

  catPositiveCategories: string[] = [];
  catPositiveSeries: any[] = [];

  projHazardCategories: string[] = [];
  projHazardSeries: any[] = [];

  catHazardCategories: string[] = [];
  catHazardSeries: any[] = [];

  cleanUpDataSubs: Subscription;
  cleanUpDataSubs1: Subscription;
  cleanUpDataSubs2: Subscription;
  cleanUpDataSubs3: Subscription;

  constructor(private dashService: DashboardService, private fb: UntypedFormBuilder) {

    this.cleanUpDataSubs = this.dashService._chartsPositve$
      .subscribe((data: Sors[]) => {
        const sorsObserverCompany = {};
        const sorsProject = {};
        data?.forEach((el: Sors) => {
          sorsObserverCompany[el.SORS_OBSERVER_COMPANY] =  (sorsObserverCompany[el.SORS_OBSERVER_COMPANY] || 0) + 1;
          sorsProject[el.SORS_PROJECT] = (sorsProject[el.SORS_PROJECT] || 0) + 1;
        });
        const resulstArr = Object.entries(sorsProject).map(([key, value]) => ({ key, value }));
        const filtredProject = resulstArr.filter((value) => value.key !== 'undefined' && value.key !== '');
        filtredProject.map((data) => {
          this.projPositiveCategories.push(data.key);
          this.projPositiveSeries.push(data.value);
        });

        console.log(sorsObserverCompany, 'OBS POSITIVE')
        console.log(filtredProject, 'PROJ POSITIVE')
      });


    this.cleanUpDataSubs2 = this.dashService._chartsHazard$
      .subscribe((data: Sors[]) => {
        const sorsObserverCompany = {};
        const sorsProject = {};
        data?.forEach((el: Sors) => {
          sorsObserverCompany[el.SORS_OBSERVER_COMPANY] =
            (sorsObserverCompany[el.SORS_OBSERVER_COMPANY] || 0) + 1;

          sorsProject[el.SORS_PROJECT] =
            (sorsProject[el.SORS_PROJECT] || 0) + 1;
        });
        const resulstArr = Object.entries(sorsProject).map(([key, value]) => ({ key, value }));
        const filtredProject = resulstArr.filter((value) => value.key !== 'undefined' && value.key !== '');
        filtredProject.map((data) => {
          this.projHazardCategories.push(data.key);
          this.projHazardSeries.push(data.value);
        });

        console.log(sorsObserverCompany, 'OBS HAZARD')
        console.log(filtredProject, 'PROJ HAZARD')
      });



    this.cleanUpDataSubs1 = this.dashService._chartsPositve$
      .subscribe((data: Sors[]) => {
        const arr: string[] = [];
        data?.forEach((data: Sors) => {
          if (data.SORS_OBS_CATEGORY instanceof Array) {
            data.SORS_OBS_CATEGORY.map((el) => arr.push(el));
          } else { arr.push(data.SORS_OBS_CATEGORY) };
        });

        const result = arr.reduce((acc, curr) => ((acc[curr] = (acc[curr] || 0) + 1), acc), {});
        const resulstArr = Object.entries(result).map(([key, value]) => ({ key, value }));
        const filtredCategory = resulstArr.filter((value) => value.key !== 'undefined' && value.key !== '');
        const sortedPositiveTags = filtredCategory.sort((firstItem: any, secondItem: any) => secondItem.value - firstItem.value).slice(0, 5);

        sortedPositiveTags.map((data) => {
          this.catPositiveCategories.push(data.key);
          this.catPositiveSeries.push(data.value);
        });

        console.log(filtredCategory, 'TAGS POSITIVE')
      });


    this.cleanUpDataSubs3 = this.dashService._chartsHazard$
      .subscribe((data: Sors[]) => {
        const arr: string[] = [];
        data?.forEach((data: Sors) => {
          if (data.SORS_OBS_CATEGORY instanceof Array) {
            data.SORS_OBS_CATEGORY.map((el) => arr.push(el));
          } else { arr.push(data.SORS_OBS_CATEGORY) };
        });

        const result = arr.reduce((acc, curr) => ((acc[curr] = (acc[curr] || 0) + 1), acc), {});
        const resulstArr = Object.entries(result).map(([key, value]) => ({ key, value }));
        const filtredCategory = resulstArr.filter((value) => value.key !== 'undefined' && value.key !== '');
        const sortedHazardTags = filtredCategory.sort((firstItem: any, secondItem: any) => secondItem.value - firstItem.value).slice(0, 5);
        
        sortedHazardTags.map((data) => {
          this.catHazardCategories.push(data.key);
          this.catHazardSeries.push(data.value);
        });

        console.log(filtredCategory, 'TAGS HAZARD')
      });


    

    this.common = {
      chart: {
        type: 'bar',
        width: 800,
        height: 500,
        selection: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: '12px',
          colors: ['#fff'],
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff'],
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: true,
        position: 'bottom',
      },
      tooltip: {
        enabled: true,
      },
      responsive:
      
      [
        {

          breakpoint: 580,
          
          options: {
            chart: {
              width: 350,
            },
            plotOptions: {},
            legend: {
              position: 'bottom',
            },
            yaxis: {
              show: false,
            },
          },
        },
      ],
    };

    // POSITIVE OBS GRAPS
    this.projectPositive = {
      series: [ 
        { 
          name: 'count',
          data: this.projPositiveSeries,
          color: '#002d72',
        }
      ],
      xaxis: { categories: this.projPositiveCategories}, 
      title: { text: 'Positive Observation' },
    };

    this.obsPositiveCategory = {
      series: [
        { 
          name: 'count', 
          data: this.catPositiveSeries,
          color: '#002d72',
        }
      ],
      xaxis: { categories: this.catPositiveCategories },
      title: { text: 'Positive Observation' },
    };



// HAZARD GRAPS
    this.projectHazard = {
      series: [{
        name: 'count',
        data: this.projHazardSeries,
        color: '#002d72',
      }],
      xaxis: { categories: this.projHazardCategories},
      title: { text: 'Hazard' },
    };

    this.obsHazardCategory = {
      series: [
        { 
          name: 'count',
          data: this.catHazardSeries,
          color: '#002d72',
        }
      ],
      xaxis: { categories: this.catHazardCategories },
      title: { text: 'Hazard' },
    };
    

  }

  toggleCheckbox(event: Event, tag: string) {
    event.stopPropagation();
    const checkboxControl = this.selection.get(tag);
    checkboxControl?.setValue(!checkboxControl.value);
  } 

  handleKeyPress(event: KeyboardEvent, tag: string) {
    if (event.key === 'Enter' || event.key === 'Space') {
      event.preventDefault();
      this.toggleCheckbox(event, tag);
    }
  }
  
  ngOnInit(): void {
    const choice = localStorage.getItem('DASHBOARD_CHOICE');
    if (choice) {
      this.selection.setValue(JSON.parse(choice));
    }

    this.selection.valueChanges
      .pipe(filter(() => this.selection.valid))
      .subscribe((val) => localStorage.setItem('DASHBOARD_CHOICE', JSON.stringify(val)));
   
    //  this.updateSeries()
  }



  chartData$ = this.dashService._chartsData$


  ngOnDestroy(): void {
    this.cleanUpDataSubs.unsubscribe();
    this.cleanUpDataSubs1.unsubscribe();
    this.cleanUpDataSubs2.unsubscribe();
    this.cleanUpDataSubs3.unsubscribe();
    localStorage.removeItem('DASHBOARD_CHOICE');
  }

  // public updateSeries() {
  //   const data = {
  //     name: 'count',
  //     data: this.projPositiveSeries,
  //     color: '#002d72',
  //   }
    
  //   this.projectPositive.series.push(data)
  // }
}
