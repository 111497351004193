<mat-card class="mat-elevation-z0" class="wrapper-card">
  <mat-card-title class="centerRow" style="margin-top: 10px">
    Dashboard
  </mat-card-title>

  <div *ngIf="chartData$ | async; else spinner"></div>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon color="primary" style="margin-right: 10px">
          add_chart
        </mat-icon>
        Graphs
      </mat-panel-title>

      <mat-panel-description>
        Choose Your Graphs
        <mat-icon>bar_chart</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-chip-grid>
      <mat-chip-row [formGroup]="selection" *ngFor="let value of graphs; index as i" (click)="toggleCheckbox($event, value.tag)" (keypress)="handleKeyPress($event, value.tag)">
        <div class="clickable-container" (keypress)="$event.stopPropagation()">
          <mat-checkbox formControlName="{{ value.tag }}" [color]="value.color" (click)="$event.stopPropagation()" (keypress)="$event.stopPropagation()">
            {{ value.name }}
          </mat-checkbox>
          <mat-icon (click)="$event.stopPropagation()" (keypress)="$event.stopPropagation()">{{ value.icon }}</mat-icon>
        </div>
      </mat-chip-row>
    </mat-chip-grid>        
  </mat-expansion-panel>

  <ng-container *ngIf="selection.value.One === true">
    <mat-card class="mat-elevation-z3" class="spacing">
      <mat-card-subtitle>
        Financial Year {{ fiscalyear }} Project
      </mat-card-subtitle>
      <div class="centerRow">
        <apx-chart
          [series]="projectPositive.series"
          [xaxis]="projectPositive.xaxis"
          [title]="projectPositive.title"
          [chart]="common.chart"
          [dataLabels]="common.dataLabels"
          [plotOptions]="common.plotOptions"
          [stroke]="common.stroke"
          [responsive]="common.responsive"
        ></apx-chart>
      </div>
    </mat-card>
  </ng-container>

  <ng-container *ngIf="selection.value.Two === true">
    <mat-card class="mat-elevation-z3" class="spacing">
      <mat-card-subtitle>
        Financial Year {{ fiscalyear }} Project
      </mat-card-subtitle>
      <div class="centerRow">
        <apx-chart
          [series]="projectHazard.series"
          [xaxis]="projectHazard.xaxis"
          [title]="projectHazard.title"
          [chart]="common.chart"
          [dataLabels]="common.dataLabels"
          [plotOptions]="common.plotOptions"
          [stroke]="common.stroke"
          [responsive]="common.responsive"
        ></apx-chart>
      </div>
    </mat-card>
  </ng-container>

  <ng-container *ngIf="selection.value.Three === true">
    <mat-card class="mat-elevation-z3" class="spacing">
      <mat-card-subtitle>
        Financial Year {{ fiscalyear }} Category
      </mat-card-subtitle>

      <div class="centerRow">
        <apx-chart
          [series]="obsPositiveCategory.series"
          [title]="obsPositiveCategory.title"
          [xaxis]="obsPositiveCategory.xaxis"
          [chart]="common.chart"
          [dataLabels]="common.dataLabels"
          [plotOptions]="common.plotOptions"
          [stroke]="common.stroke"
          [responsive]="common.responsive"
        ></apx-chart>
      </div>
    </mat-card>
  </ng-container>

  <ng-container
    class="mat-elevation-z3"
    class="spacing"
    *ngIf="selection.value.Four === true"
  >
    <mat-card class="mat-elevation-z3" class="spacing">
      <mat-card-subtitle>
        Financial Year {{ fiscalyear }} Category
      </mat-card-subtitle>

      <div class="centerRow">
        <apx-chart
          [series]="obsHazardCategory.series"
          [title]="obsHazardCategory.title"
          [xaxis]="obsHazardCategory.xaxis"
          [chart]="common.chart"
          [dataLabels]="common.dataLabels"
          [plotOptions]="common.plotOptions"
          [stroke]="common.stroke"
          [responsive]="common.responsive"
        ></apx-chart>
      </div>
    </mat-card>
  </ng-container>
</mat-card>

<ng-template #spinner>
  <mat-spinner> Fetching Data </mat-spinner>
</ng-template>
