<mat-tab-group>
  <mat-tab label="New Safety Observations">
    <app-sors></app-sors>
  </mat-tab>
  <mat-tab label="Search Safety Observations" *ngIf="!b2bUser">
    <app-sors-search></app-sors-search>
  </mat-tab>
  <mat-tab label="Dashboard" *ngIf="!b2bUser">
    <app-dashboard></app-dashboard>
  </mat-tab>

</mat-tab-group>